<script setup lang="ts">
import {ref, watch} from 'vue';
import {CatalogMenuCategoryFilter, CatalogMenuFilterCategoryEnum} from "~vue/catalog-menu/types";

const props = defineProps<{
    categories: CatalogMenuCategoryFilter[]
    groupsId: string
    modelValue: string
}>()

const emit = defineEmits(['update:modelValue']);

const allCategories = CatalogMenuFilterCategoryEnum.ALL;
const activeCategoryId = ref(props.modelValue || allCategories);

watch(() => props.modelValue, (newValue) => {
    activeCategoryId.value = newValue;
});

const setActiveCategory = (categoryId: string) => {
    activeCategoryId.value = categoryId;
    emit('update:modelValue', categoryId); // Сообщаем об изменении v-model
}
</script>

<template>
    <div class="categories-filter">
        <button
            :class="{
                'categories-filter__button': true,
                'categories-filter__button--selected': allCategories === activeCategoryId
            }"
            aria-label="Показать все категории"
            :aria-controls="groupsId"
            type="button"
            @click="setActiveCategory(allCategories)"
        >
            Все
        </button>

        <button
            v-for="category in categories"
            :key="category.id"
            :class="{
                'categories-filter__button': true,
                'categories-filter__button--selected': category.id === activeCategoryId
            }"
            :aria-label="`Фильтровать категории по ${category.title}`"
            :aria-controls="groupsId"
            type="button"
            @click="setActiveCategory(category.id)"
        >
            {{ category.title }}
        </button>
    </div>
</template>

<style scoped lang="scss">
.categories-filter {
    @apply flex
        md:justify-center
        hide-scrollbar
        md:flex-wrap
        gap-2;

    &__button {
        transition-property: color, background-color, border-color, opacity;

        @apply inline-flex
            bg-pale-sky-10
            w-max
            px-4
            py-1
            rounded
            text-body-small
            cursor-pointer
            whitespace-nowrap
            fade-hover-effect
            text-pale-sky-70;

        &--selected {
            @apply bg-pale-sky-70
            text-white
            cursor-default;
        }
    }
}
</style>
