<script setup lang="ts">
import {CatalogMenuCategory, CatalogMenuFilterCategoryEnum} from "~vue/catalog-menu/types";
import {computed, ref, watch} from "vue";
import {calcInnerBanner, getBottomBannerSources} from "~vue/catalog-menu/lib";
import CatalogMenuLinksGroup from "~vue/catalog-menu/ui/CatalogMenuLinksGroup.vue";
import CatalogCategoriesFilter from "~vue/catalog-menu/ui/CatalogCategoriesFilter.vue";
import ImageBanner from "~vue/banner/ui/ImageBanner.vue";
import CatalogMenuBrands from "~vue/catalog-menu/ui/CatalogMenuBrands.vue";

const props = defineProps<{
	category: CatalogMenuCategory
}>()

const allCategories = CatalogMenuFilterCategoryEnum.ALL
const activeCategory = ref<string>(allCategories)
const groupsId = 'category-groups-id'

const listEl = ref<HTMLElement | null>(null)
const rightBannerEl = ref<HTMLElement | null>(null)
const bottomBannerEl = ref<HTMLElement | null>(null)

const { inner } = calcInnerBanner(listEl, rightBannerEl, bottomBannerEl)

const _bottomBannerSources = computed(() => getBottomBannerSources(inner.value, props.category.banners?.bottom))
</script>

<template>
	<div class="categories">
		<div class="categories__header">
			<div class="categories__title">
				{{ category.title }}
			</div>

			<div
				v-if="category.filters && category.filters.categories && category.filters.categories.length > 0"
				class="categories__filters"
			>
				<div
					class="categories__filters-categories"
				>
					<CatalogCategoriesFilter
						v-model="activeCategory"
						:groups-id="groupsId"
						:categories="category.filters.categories"
					/>
				</div>
			</div>
		</div>

		<ul
			:id="groupsId"
			ref="listEl"
			:class="{
				categories__list: true,
				'categories__list--slim': category && category.banners?.right,
			}"
			:aria-label="`Подразделы раздела каталога ${category.title}`"
			aria-live="polite"
			aria-atomic="true"
		>
			<template v-for="group in category.groups">
				<li
					v-if="
						activeCategory === allCategories ||
						group.categories.includes(activeCategory)
					"
					:key="group.id"
					class="categories__item"
				>
					<CatalogMenuLinksGroup :group="group" />
				</li>
			</template>
		</ul>

		<div
			v-if="category.banners && category.banners.right"
			ref="rightBannerEl"
			:class="{
				'categories__banner-right': true,
				'categories__banner-right--large': inner,
			}"
		>
            <ImageBanner :banner="category.banners.right" />
		</div>

		<div
			v-if="category.banners && category.banners.bottom"
			ref="bottomBannerEl"
			:class="{
				'categories__banner-bottom': true,
				'categories__banner-bottom--inner': inner,
			}"
		>
            <ImageBanner
                :banner="category.banners.bottom"
                :sources="_bottomBannerSources"
                advert-popup-position="top"
            />
		</div>

        <CatalogMenuBrands
            v-if="category.brands && category.brands.length > 0"
            :brands="category.brands"
            :category-name="category.title"
            :class="{
                'categories__brands': true,
                'categories__brands--down': category.banners && category.banners.bottom,
            }"
        />
	</div>
</template>

<style scoped lang="scss">
.categories {
	@apply grid
		grid-cols-10
		auto-rows-min
		gap-x-6 md:gap-x-10
		gap-y-10;

	&__header {
		@apply grid
			gap-y-10
			col-span-10 md:col-span-8
			row-start-1
			row-end-2;
	}

	&__title {
		@apply text-fluid-h1
			font-medium
			uppercase
			text-center
        ;
	}

    &__filters {
        @apply
            w-[calc(100%+24px)] md:w-full
            overflow-hidden
            overflow-x-auto
            hide-scrollbar
        ;
    }

    &__filters-categories {
        @apply
            w-max md:w-full
        ;
    }

	&__list {
		@apply columns-1 xsm:columns-2 lg:columns-3 xlg:columns-5
			h-min
			col-span-10
			gap-10
			row-start-2
			row-end-3
			max-w-full;

		&--slim {
			@apply lg:columns-2 xlg:columns-3
				lg:col-span-6 xlg:col-span-7;
		}
	}

	&__banner-right {
		@apply hidden lg:block
			h-min
			row-start-2
			row-end-3
			col-span-4 xlg:col-span-3;

		&--large {
			@apply row-start-2
				row-end-4;
		}
	}

	&__banner-bottom {
		@apply h-min
			row-start-3
			row-end-4
			col-span-10;

		&--inner {
			@apply lg:col-span-6 xlg:col-span-7;
		}
	}

	&__item {
		@apply break-inside-avoid
			pb-6 md:pb-8;
	}

    &__brands {
        @apply
            col-span-10
            row-start-3
            row-end-4
        ;

        &--down {
            @apply
                row-start-4
                row-end-5
            ;
        }
    }
}
</style>
