<script setup lang="ts">
import {CatalogMenuGroup} from "~vue/catalog-menu/types";
import isHome24 from "~/helpers/is-home24";

defineProps<{
	group: CatalogMenuGroup
}>()

const _isHome24 = isHome24();
</script>

<template>
	<div class="group">
		<a
			class="group__title"
			:href="group.url"
		>
			{{ group.title }}
			<sup class="group__count">{{ group.count }}</sup>
		</a>

		<ul
			v-if="group.list.length"
			class="group__list"
			:aria-label="`Подразделы раздела каталога ${group.title}`"
		>
			<li
				v-for="link in group.list"
				:key="link.id"
				class="group__item"
			>
				<a
					:class="{
                        'group__link': true,
                        'group__link--flag': link.highlight,
                        'group__link--flag-pink': link.highlight && _isHome24,
					}"
					:href="link.url"
				>
					{{ link.title }}
				</a>
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
.group {
	@apply flex
		flex-col
		gap-2;

	&__title {
		@apply text-overline
			font-medium;
	}

	&__count {
		@apply text-pale-sky-70
			text-caption
			font-normal;
	}

	&__list {
		@apply flex-col
			gap-2
			hidden xsm:flex;
	}

	&__item {
		@apply text-body-small;
	}

    &__link {
        @apply
            duration-300
            transition-[color]
            ease-ease;

        &:hover {
            @apply text-pale-sky-70;
        }

        &--flag {
            @apply
                inline-flex
                items-center
                gap-2.5
            ;

            &:after {
                content: "";
                background-color: #33CCCC;

                @apply
                    w-[5px]
                    h-[5px]
                    rounded-full
                ;
            }
        }

        &--flag-pink {
            &:after {
                background-color: #E9CCBC;
            }
        }
    }
}
</style>
